import React, { useEffect } from 'react'

import FullScreenLoading from '../FullScreenLoading'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function FullscreenV2({ children, isLoading, transparent }) {
  useEffect(() => {
    const iframe = window.parent.document.querySelector(
      '#canvas-region > iframe'
    )
    iframe && iframe.classList.add('fullscreen')
    document.body.classList.add('fullscreen')

    return () => {
      iframe && iframe.classList.remove('fullscreen')
      document.body.classList.remove('fullscreen')
    }
  }, [])

  return (
    <div
      className={classNames(styles.fullscreen, {
        [styles.transparent]: transparent
      })}
    >
      {isLoading ? <FullScreenLoading /> : children}
    </div>
  )
}
