.react-app ._fullscreen_151gl_1 {
  position: absolute;
  top: 0;
  width: 100dvw;
  left: 0;
  height: 100dvh;
  background-color: white;
  z-index: 900;
  overflow: hidden;
}

.react-app ._transparent_151gl_12 {
  background-color: transparent;
}