import grapesjs from 'grapesjs'
import { compile } from 'html-to-text'

const compiledConvert = compile({})

export const prepareGrapejsToSave = (editorAsArg) => {
  const editor = editorAsArg || grapesjs.editors?.[0]
  const html = editor.runCommand('mjml-code-to-html')?.html
  const mjml = editor.runCommand('mjml-code')

  return { html, mjml, text: compiledConvert(html) }
}
